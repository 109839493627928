import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import Image from "gatsby-image"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"

/* Styles */
import "../../styles/pages/the-hybrid.scss"

const GwtPage = () => {
  const { bgGwt, bgMobileGwt, logoGwt } = useStaticQuery(
    graphql`
      query {
        bgGwt: file(relativePath: { eq: "the-hybrid/gwt/gwt-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bgMobileGwt: file(
          relativePath: { eq: "the-hybrid/gwt/mobile-bg-gwt.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logoGwt: file(relativePath: { eq: "the-hybrid/gwt/gwt-logo.png" }) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageBgGwt = bgGwt.childImageSharp.fluid
  const imgBgMobileGwt = bgMobileGwt.childImageSharp.fluid
  const imgLogoGwt = logoGwt.childImageSharp.fluid

  // Background-Image Breakpoints
  const sources = [
    imgBgMobileGwt,
    {
      ...imageBgGwt,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout bgImage={sources}>
      <SEO
        title="gwt"
        keywords={[
          `Agencia`,
          `Transformacion Digital`,
          `The Hybrid`,
          `Growth`,
          `Rosario`,
          `Innovación`,
        ]}
      />
      <section id="gwt-page" className="the-hybrid-inner-pages mt-lg-5">
        <Container>
          <Row>
            <Col className="d-none d-lg-block pt-5" xs={12} lg={2}>
              <p className="text-white mt-4">
                <Link
                  className="text-primary text-decoration-none"
                  to="/the-hybrid"
                >
                  _thehybrid
                </Link>
                {`>gwt`}
              </p>
            </Col>
            <Col xs={12} lg={4}>
              <div className="inner-logo-images">
                <Image className="img-fluid" fluid={imgLogoGwt} />
              </div>
            </Col>
            <Col className="" xs={12} lg={6}>
              <p className="text-content text-white">
                Acompañamos a las marcas, compañías o nuevas empresas en el
                crecimiento a través de la experimentación activa y el valor de
                la adaptación a nuevos entornos y mercados simples o complejos.
              </p>
              <p className="text-content text-white">
                Nuestra experiencia en el desarrollo de productos y servicios
                tech, con foco fundacional en el “growth” nos permite lograr
                resultados concretos y a éxito siendo parte del modelo.
              </p>
              <p className="text-content text-white">
                Para crecer es necesario un aprendizaje continuo, creatividad y
                un equipo multidisciplinario de especialistas que aceleren la
                construcción real de productos o servicios de escala y nuestro
                equipo es el adecuado para ser el socio de negocio.
              </p>
              <p className="text-content text-white">
                Nuestra metodología Growth Agile acelerará su plan de negocios y
                mostrará resultados a corto plazo. Vayamos juntos al próximo
                nivel de mercado o desarrollo entendiendo porqué hace lo que
                hace.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default GwtPage
